import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Global, css } from "@emotion/react";

import { setVisibleInputPhone } from "./logic/actions/param";
import HomePage from "./pages/home";

const App = ({ setVisibleInputPhone }) => {
  const [fontName, setFontName] = useState("Open Sans");
  useEffect(() => {
    const node_root = document.getElementById("demenagement");
    const font_name = node_root.dataset.font;
    setFontName(font_name ? font_name : "");
    if (node_root.dataset.phone === "true") {
      setVisibleInputPhone(true);
    }
  }, []);
  return (
    <div className="container">
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=${fontName
            .split(" ")
            .join("+")}");

          #demenagement {
            font-family: ${fontName && fontName};
          }
        `}
      />
      <HomePage />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setVisibleInputPhone: bindActionCreators(setVisibleInputPhone, dispatch),
});

export default connect(null, mapDispatchToProps)(App);
