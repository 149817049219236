export const GET_PARAMS = "GET_PARAMS";
export const CREATE_LEAD_ID = "CREATE_LEAD_ID";
export const SET_LEAD_ID = "SET_LEAD_ID";
export const SET_INFO_STEP_1 = "SET_INFO_STEP_1";
export const SET_INFO_STEP_2 = "SET_INFO_STEP_2";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const SEND_DEVIS = "SEND_DEVIS";
export const SET_SERVICES = "SET_SERVICES";
export const SET_VISIBLE_INPUT_PHONE = "SET_VISIBLE_INPUT_PHONE";
export const SET_VISIBLE_MODAL_SUCCESS = "SET_VISIBLE_MODAL_SUCCESS";
export const SET_VISIBLE_RESULT_RESEARCH = "SET_VISIBLE_RESULT_RESEARCH";
export const RELOAD_PARAMS = "RELOAD_PARAMS";
export const SET_PROVIDERS_SOLUTION_SPECIAL = "SET_PROVIDERS_SOLUTION_SPECIAL";
export const GET_PROVIDERS_SOLUTION_SPECIAL = "GET_PROVIDERS_SOLUTION_SPECIAL";
export const SET_PROJETS_SOLUTION_SPECIAL = "SET_PROJETS_SOLUTION_SPECIAL";
export const SET_VISIBLE_STEP = "SET_VISIBLE_STEP";

export const getParams = (params) => ({
  type: GET_PARAMS,
  payload: {
    request: {
      method: "GET",
      url: "/projet",
      params,
    },
  },
});

export const createLeadId = (data) => ({
  type: CREATE_LEAD_ID,
  payload: {
    request: {
      method: "POST",
      url: "/lead",
      data,
    },
  },
});

export const setLeadId = (lead_id) => ({
  type: SET_LEAD_ID,
  payload: { lead_id },
});

export const setInfoStep1 = (payload) => ({
  type: SET_INFO_STEP_1,
  payload,
});

export const setInfoStep2 = (payload) => ({
  type: SET_INFO_STEP_2,
  payload,
});

export const getProviders = (data) => ({
  type: GET_PROVIDERS,
  payload: {
    request: {
      method: "POST",
      url: "/prestataires",
      data,
    },
  },
});

export const setProviders = (providers) => ({
  type: SET_PROVIDERS,
  payload: { providers },
});

export const setServices = (services) => ({
  type: SET_SERVICES,
  payload: { services },
});

export const sendDevis = (data) => ({
  type: SEND_DEVIS,
  payload: {
    request: {
      method: "POST",
      url: "/devis",
      data,
    },
  },
});

export const setVisibleInputPhone = (visible) => ({
  type: SET_VISIBLE_INPUT_PHONE,
  payload: { visible },
});

export const setVisibleModalSuccess = (visible) => ({
  type: SET_VISIBLE_MODAL_SUCCESS,
  payload: { visible },
});

export const setVisibleResultResearch = (visible) => ({
  type: SET_VISIBLE_RESULT_RESEARCH,
  payload: { visible },
});

export const reloadParams = () => ({
  type: RELOAD_PARAMS,
});

export const getProvidersSolutionSpecial = ({ data, truckSource }) => {
  return {
    type: GET_PROVIDERS_SOLUTION_SPECIAL,
    payload: {
      request: {
        method: "POST",
        url: `/truck/source/${truckSource}`,
        data,
      },
    },
  };
};

export const setProvidersSolutionSpecial = (data) => ({
  type: SET_PROVIDERS_SOLUTION_SPECIAL,
  payload: data,
});

export const setProjetsSolutionSpecial = (data) => ({
  type: SET_PROJETS_SOLUTION_SPECIAL,
  payload: data,
});

export const setVisibleStep = (visible) => ({
  type: SET_VISIBLE_STEP,
  payload: { visible },
});
