import Title from "../common/Title";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import Button from "../common/Button";
import ButtonFixed from "components/common/ButtonFixed";

const Step3 = ({
  onFinishStep3,
  servicesReducer,
  loading,
  selectedService,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [services, setServices] = useState([]);
  const handleChangeService = (e) => {
    if (e.target.checked) {
      setServices([...services, Number(e.target.value)]);
    } else {
      setServices(
        services.filter((item) => Number(item) !== Number(e.target.value))
      );
    }
  };

  useEffect(() => {
    setServices(selectedService);
  }, [selectedService]);

  return (
    <div className="demenagement-step3">
      <Title className="mb-2" title={t("services")} />

      <div>
        {servicesReducer
          ? servicesReducer.map((service) => (
              <div key={service.id} className="mb-3">
                <label
                  htmlFor={service.nom}
                  className="d-flex align-items-center"
                >
                  <input
                    hidden
                    type="checkbox"
                    id={service.nom}
                    value={service.id}
                    defaultChecked={services.includes(service.id)}
                    name="services"
                    onChange={handleChangeService}
                  />
                  <span
                    className="checkmark"
                    style={{
                      backgroundColor: `${
                        services.includes(Number(service.id))
                          ? theme.color2
                          : "#fff"
                      }`,
                      borderColor: `${
                        services.includes(Number(service.id))
                          ? theme.color2
                          : "#c4c4c4"
                      }`,
                    }}
                  ></span>
                  <span className="pl-2" style={{ color: theme.color1 }}>{service.nom}</span>
                </label>
              </div>
            ))
          : ""}
      </div>

      <div className="mt-3">
          <Button
            className="ml-lg-auto"
            onClick={() => onFinishStep3(services)}
            isLoading={loading}
          >
            {t("seeTheBestOffers")}
          </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  servicesReducer: state.params.data.services,
  selectedService: state.params.services,
});

export default connect(mapStateToProps, null)(Step3);
