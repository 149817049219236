export const SET_DATA_HOME_FORM = "SET_DATA_HOME_FORM";
export const SET_LIST_ACTIVE_STEP = "SET_LIST_ACTIVE_STEP";
export const SET_ACTIVE_KEY_STEP = "SET_ACTIVE_KEY_STEP";
export const RELOAD_BASES = "RELOAD_BASES";
export const SET_EMAIL = "SET_EMAIL";

export const setDataHomeForm = (data) => ({
  type: SET_DATA_HOME_FORM,
  payload: {
    data,
  },
});

export const setListActiveStep = (steps) => ({
  type: SET_LIST_ACTIVE_STEP,
  payload: { steps },
});

export const setActiveKeyStep = (step_name) => ({
  type: SET_ACTIVE_KEY_STEP,
  payload: { step_name },
});

export const reloadBases = () => ({
  type: RELOAD_BASES,
});

export const setAddressEmail = (email) => ({
  type: SET_EMAIL,
  payload: { email }
})
