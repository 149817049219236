import { ReactComponent as IconHelp } from "../../assets/images/icon-help.svg";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Title = ({ className, title, hiddenIcon, ...props }) => {
  const theme = useTheme();
  const entering = (e) => {
    e.children[0].style.borderTopColor = theme.color1;
    e.children[1].style.backgroundColor = theme.color1;
    e.children[1].style.maxWidth = '400px'
  };
  return (
    <h4 className={`demenagement__title ${className && className}`} {...props}>
      <span className="pr-2" style={{ color: theme.color1 }}>
        {title}
      </span>

      {!hiddenIcon && props.tooltipTitle && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip
              id={`tooltip-bottom`}
              style={{ "--color-tooltip": theme.color1 }}
            >
              {props.tooltipTitle}
            </Tooltip>
          }
          onEntering={entering}
        >
          <IconHelp fill={theme.color2} />
        </OverlayTrigger>
      )}
    </h4>
  );
};

export default Title;
