import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { format } from "date-fns";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import HomeForm from "../../components/home-form";
import Step1 from "../../components/step1";
import Step2 from "../../components/step2";
import Step3 from "../../components/step3";
import ResultSearch from "../../components/result-search";
import {
  createLeadId,
  setLeadId,
  getParams,
  sendDevis,
  setInfoStep1,
  setInfoStep2,
  getProviders,
  setProviders,
  setServices,
  reloadParams,
  getProvidersSolutionSpecial,
  setProvidersSolutionSpecial,
  setProjetsSolutionSpecial,
  setVisibleStep
} from "../../logic/actions/param";
import {
  setActiveKeyStep,
  setDataHomeForm,
  setListActiveStep,
  reloadBases,
} from "../../logic/actions/base";

import "../../assets/style/home.scss";
import { ID_SOLUTION_SPECIAL } from "../../constants";
import {
  sendTrackData,
  setTrackAction,
  setTrackLead,
  setTrackQuote,
  setTrackSelection,
  setTrackLeadId,
} from "logic/actions/track";
import { store } from "logic";

const showErrorToast = () =>
  toast.error("Something went wrong. Please try again later");

const node_root = document.getElementById("demenagement");
const lang = node_root.dataset.lang;

const HomePage = ({
  getParams,
  createLeadId,
  setLeadId,
  setInfoStep1,
  setInfoStep2,
  visibleStep,
  visibleResultSearch,
  params,
  getProviders,
  setProviders,
  sendDevis,
  setServices,
  visibleInputPhone,
  setDataHomeForm,
  setActiveKeyStep,
  setListActiveStep,
  activeKeyStep,
  listActiveStep,
  setReloadBases,
  setReloadParams,
  nomBases,
  emailBases,
  getProvidersSolutionSpecial,
  setProvidersSolutionSpecial,
  setProjetsSolutionSpecial,
  projetsSolutionSpecial,
  providers,
  bases,
  setTrackLead,
  setTrackAction,
  setTrackSelection,
  setTrackQuote,
  sendTrackData,
  setTrackLeadId,
  setVisibleStep
}) => {
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);

  const [loadingStep3, setLoadingStep3] = useState(false);
  const [loadingHomeForm, setLoadingHomeForm] = useState(false);
  const [loadingResultSearch, setLoadingResultSearch] = useState(false);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [loadingBtnSend, setLoadingBtnSend] = useState(false);

  const email = query.get("email");
  const nom = query.get("name");

  const utm_source = query.get("utm_source");
  const utm_medium = query.get("utm_medium");
  const utm_campaign = query.get("utm_campaign");
  const utm_content = query.get("utm_content");
  const utm_term = query.get("utm_term");

  const theme = useTheme();

  const onSubmitHomeForm = (values) => {
    const dataPost = {
      ...values,
      depart: values.depart.address_components,
      depart_location: values.depart.location,
      destination: values.destination.address_components,
      destination_location: values.destination.location,
      lang,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    };
    setLoadingHomeForm(true);
    setDataHomeForm(values);
    setTrackLead(dataPost);
    createLeadId(dataPost)
      .then(({ payload: { data } }) => {
        if (data.status) {
          setLeadId(data.data.lead_id);
          setTrackQuote({
            lead_id: data.data.lead_id,
            lang,
          });
          setTrackLeadId(data.data.lead_id);
          // if (visibleStep || visibleResultSearch) {
          //   setReloadParams();
          //   setReloadBases();
          // }
          setListActiveStep(['step1']);
          setActiveKeyStep("step1");
          if (visibleResultSearch) {
            onResearch();
          }
        } else {
          showErrorToast();
        }
        setLoadingHomeForm(false);
      })
      .catch(() => {
        showErrorToast();
        setLoadingHomeForm(false);
      });
  };

  const onSubmitFormStep1 = (values) => {
    setInfoStep1({
      surface: values.surface.toString(),
      date_depart: format(values.date_depart, "dd/MM/yyyy"),
    });
    setListActiveStep([...listActiveStep, "step2"]);
    setActiveKeyStep("step2");
    setTrackAction([
      {
        name: t("following"),
        step: "step1",
      },
    ]);
    setTrackSelection([
      {
        surface: values.surface.toString(),
        date_depart: format(values.date_depart, "dd/MM/yyyy"),
      },
    ]);
    setTrackQuote([store.getState().params]);
    sendTrackData(store.getState().track);
  };

  const onFinishStep2 = (value) => {
    setInfoStep2(value);
    setListActiveStep([...listActiveStep, "step3"]);
    setActiveKeyStep("step3");
    // Tracking
    setTrackAction([
      {
        name: t("following"),
        step: "step2",
      },
    ]);
    setTrackSelection(value);
    setTrackQuote([store.getState().params]);
    sendTrackData(store.getState().track);
  };

  const onFinishStep3 = (services) => {
    const dataPost = {
      ..._.omit(params, [
        "visibleStep",
        "visibleResultSearch",
        "data",
        "habitat_name",
        "visibleInputPhone",
        "visibleModalSuccess",
        "providers",
      ]),
      services,
      lang,
    };
    setServices(services);
    setLoadingStep3(true);
    // Set Track
    setTrackAction([
      {
        name: t("seeTheBestOffers"),
        step: "step3",
      },
    ]);

    setTrackSelection([{ services }]);
    setTrackQuote([store.getState().params]);
    sendTrackData(store.getState().track);

    getProviders(dataPost)
      .then((result) => {
        if (result?.payload?.data.status) {
          const promises = params?.data?.truck_source.map(function (
            truckSource
          ) {
            return getProvidersSolutionSpecial({ data: dataPost, truckSource });
          });

          Promise.all([...promises])
            .then(function (res) {
              const results = res.reduce((previousValue, currentValue) => {
                return [
                  ...previousValue,
                  ...currentValue.payload?.data?.data?.data,
                ];
              }, []);
              setProviders({
                ...result?.payload?.data?.data,
                solution_3: results.length,
              });
              setProjetsSolutionSpecial(results);
              setLoadingStep3(false);
            })
            .catch(() => {
              showErrorToast();
              setLoadingStep3(false);
            });
        }
      })
      .catch(() => {
        showErrorToast();
        setLoadingStep3(false);
      });
  };

  const onChangeCurrentSolution = (current_solution) => {
    const dataPost = {
      ..._.omit(params, [
        "visibleStep",
        "visibleResultSearch",
        "data",
        "habitat_name",
        "visibleInputPhone",
        "visibleModalSuccess",
        "providers",
      ]),
      current_solution,
      lang,
    };
    setLoadingResultSearch(true);
    // const promises = params?.data?.truck_source.map(function (truckSource) {
    //   return getProvidersSolutionSpecial({ data: dataPost, truckSource });
    // });
    if (current_solution.toString() === ID_SOLUTION_SPECIAL) {
      setProvidersSolutionSpecial(projetsSolutionSpecial);
      setLoadingResultSearch(false);
      // Promise.all(promises).then(function (res) {
      //   const results = res.reduce((previousValue, currentValue) => {
      //     return [...previousValue, ...currentValue.payload?.data?.data?.data];
      //   }, []);
      //   setProvidersSolutionSpecial(results);
      //   setLoadingResultSearch(false);
      // });
    } else {
      getProviders(dataPost)
        .then(function (res) {
          setProviders({
            projets: res.payload?.data?.data?.projets,
            ..._.omit(providers, ["projets"]),
          });
          setLoadingResultSearch(false);
        })
        .catch(() => {
          showErrorToast();
        });
    }
  };

  const onFinishResultSearch = (data) => {
    setLoadingBtnSend(true);
    setTrackSelection(data);
    setTrackAction([
      {
        name: t("toSend"),
        step: "send",
      },
    ]);
    sendTrackData(store.getState().track);
    sendDevis({
      phone: bases?.phone,
      email: emailBases,
      nom: nomBases,
      checkMark: bases.checkMark,
      depart: bases?.depart,
      destination: bases?.destination,
      arrivalSituation: params?.arrivalSituation,
      current_solution: params?.current_solution,
      date_depart: params?.date_depart,
      habitat_depart_id: params?.habitat_depart_id,
      habitat_depart_name: params?.habitat_depart_name,
      habitat_destionation_id: params?.habitat_destionation_id,
      habitat_destionation_name: params?.habitat_destionation_name,
      lead_id: params?.lead_id,
      projetsSolutionSpecial: params?.projetsSolutionSpecial,
      services: params?.services,
      surface: params?.surface,
      selections: data,
      lang,
    })
      .then(({ payload: { data } }) => {
        if (data.status) {
          setVisibleModalSuccess(true);
        } else {
          showErrorToast();
        }
        setLoadingBtnSend(false);
      })
      .catch(() => {
        showErrorToast();
        setLoadingBtnSend(false);
      });
  };

  const onResearch = () => {
    const dataPost = store.getState().params;
    getProviders(dataPost)
      .then((result) => {
        if (result?.payload?.data.status) {
          const promises = params?.data?.truck_source.map(function (
            truckSource
          ) {
            return getProvidersSolutionSpecial({ data: dataPost, truckSource });
          });

          Promise.all([...promises])
            .then(function (res) {
              const results = res.reduce((previousValue, currentValue) => {
                return [
                  ...previousValue,
                  ...currentValue.payload?.data?.data?.data,
                ];
              }, []);
              setProviders({
                ...result?.payload?.data?.data,
                solution_3: results.length,
              });
              setProjetsSolutionSpecial(results);
              setLoadingHomeForm(false);
            })
            .catch(() => {
              showErrorToast();
              setLoadingHomeForm(false);
            });
        }
      })
      .catch(() => {
        showErrorToast();
        setLoadingHomeForm(false);
      });
  };

  const backStep = () => {
    switch (activeKeyStep) {
      case "step1":
        setListActiveStep([]);
        setActiveKeyStep("");
        setVisibleStep( false);
        break;
      case "step2":
        setListActiveStep(["step1"]);
        setActiveKeyStep("step1");
        break;

      case "step3":
        setListActiveStep(["step1", "step2"]);
        setActiveKeyStep("step2");
        break;
      default: 
        setListActiveStep("");
        // setActiveKeyStep([]);
      }
        
    };

  useEffect(() => {
    getParams({ lang: lang });
    if (!nomBases && !emailBases) {
      setDataHomeForm({
        email,
        nom,
        depart: "",
        destination: "",
        checkMark: false,
      });
    }
  }, [email, emailBases, getParams, nom, nomBases, setDataHomeForm]);

  return (
    <div className="demenagement">
      {node_root.dataset.header === "true" && (
        <div className="mb-5 demenagement__header">
          <h4>
            <span>
              <img
                src={
                  params.data.info
                    ? params.data.info.logo
                      ? params.data.info.logo
                      : ""
                    : ""
                }
                alt="logo"
              />
            </span>
            <span>{t("theSolutionForMoving")}</span>
          </h4>
        </div>
      )}

      {!visibleStep && (
        <div className="demenagement__intro"></div>
      )}
      {visibleStep && !visibleResultSearch && (
        <div className="demenagement__intro title-step">
          <h3 className="mb-1">{t("title" + activeKeyStep)}</h3>
        </div>
      )}

      <HomeForm
        visibleStep={visibleStep}
        onSubmit={onSubmitHomeForm}
        loading={loadingHomeForm}
        visibleInputPhone={visibleInputPhone}
        visibleResultSearch={visibleResultSearch}
      />

      {/* {!visibleStep && (
        <BoxContainerSvg>
          <CityVector style={{ width: "100%" }} />
        </BoxContainerSvg>
      )} */}

      {visibleStep && !visibleResultSearch && (
        <>
          <div className="mt-5 step-wrap">
            <button onClick={backStep} className="step-back">
              <span>{t("stepBackBtn")}</span>
            </button>
            <Tabs
              activeKey={activeKeyStep}
              onSelect={(value) => setActiveKeyStep(value)}
              id="checkout-tab-mobile"
              className="step__tab"
            >
              <Tab
                eventKey="step1"
                title={
                  <p
                    className="h-100"
                    style={{
                      backgroundColor: `${
                        listActiveStep.includes("step1")
                          ? theme.color2
                          : "#d9dbdf"
                      }`,
                    }}
                  />
                }
              >
                <div className="mt-3">
                  <Step1 onSubmit={onSubmitFormStep1} />
                </div>
              </Tab>
              <Tab
                eventKey="step2"
                title={
                  <p
                    className="h-100"
                    style={{
                      backgroundColor: `${
                        listActiveStep.includes("step2")
                          ? theme.color2
                          : "#d9dbdf"
                      }`,
                    }}
                  />
                }
                disabled={!listActiveStep.includes("step2")}
              >
                <div className="mt-3">
                  <Step2 onFinishStep2={onFinishStep2} />
                </div>
              </Tab>
              <Tab
                eventKey="step3"
                title={
                  <p
                    className="h-100"
                    style={{
                      backgroundColor: `${
                        listActiveStep.includes("step3")
                          ? theme.color2
                          : "#d9dbdf"
                      }`,
                    }}
                  />
                }
                disabled={!listActiveStep.includes("step3")}
              >
                <div className="mt-3">
                  <Step3 onFinishStep3={onFinishStep3} loading={loadingStep3} />
                </div>
              </Tab>
            </Tabs>
          </div>
        </>
      )}

      {visibleStep && visibleResultSearch && (
        <ResultSearch
          onFinishResultSearch={onFinishResultSearch}
          onChangeCurrentSolution={onChangeCurrentSolution}
          loading={loadingResultSearch}
          setActiveKey={setActiveKeyStep}
          visibleModalSuccess={visibleModalSuccess}
          setVisibleModalSuccess={setVisibleModalSuccess}
          loadingBtnSend={loadingBtnSend}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  visibleStep: state.params.visibleStep,
  params: state.params,
  visibleResultSearch: state.params.visibleResultSearch,
  visibleInputPhone: state.params.visibleInputPhone,
  listActiveStep: state.bases.listActiveStep,
  activeKeyStep: state.bases.activeKeyStep,
  nomBases: state.bases.nom,
  emailBases: state.bases.email,
  projetsSolutionSpecial: state.params.projetsSolutionSpecial,
  providers: state.params.providers,
  bases: state.bases,
  track: state.track,
});

const mapDispatchToProps = (dispatch) => ({
  getParams: bindActionCreators(getParams, dispatch),
  createLeadId: bindActionCreators(createLeadId, dispatch),
  setLeadId: bindActionCreators(setLeadId, dispatch),
  setInfoStep1: bindActionCreators(setInfoStep1, dispatch),
  setInfoStep2: bindActionCreators(setInfoStep2, dispatch),
  getProviders: bindActionCreators(getProviders, dispatch),
  setProviders: bindActionCreators(setProviders, dispatch),
  setServices: bindActionCreators(setServices, dispatch),
  sendDevis: bindActionCreators(sendDevis, dispatch),
  setDataHomeForm: bindActionCreators(setDataHomeForm, dispatch),
  setActiveKeyStep: bindActionCreators(setActiveKeyStep, dispatch),
  setListActiveStep: bindActionCreators(setListActiveStep, dispatch),
  setReloadParams: bindActionCreators(reloadParams, dispatch),
  setReloadBases: bindActionCreators(reloadBases, dispatch),
  getProvidersSolutionSpecial: bindActionCreators(
    getProvidersSolutionSpecial,
    dispatch
  ),
  setProvidersSolutionSpecial: bindActionCreators(
    setProvidersSolutionSpecial,
    dispatch
  ),
  setProjetsSolutionSpecial: bindActionCreators(
    setProjetsSolutionSpecial,
    dispatch
  ),
  setTrackAction: bindActionCreators(setTrackAction, dispatch),
  setTrackLead: bindActionCreators(setTrackLead, dispatch),
  setTrackSelection: bindActionCreators(setTrackSelection, dispatch),
  setTrackQuote: bindActionCreators(setTrackQuote, dispatch),
  sendTrackData: bindActionCreators(sendTrackData, dispatch),
  setTrackLeadId: bindActionCreators(setTrackLeadId, dispatch),
  setVisibleStep: bindActionCreators(setVisibleStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
