import { useTheme } from "@emotion/react";

const ScaleLoader = () => {
  const theme = useTheme();
  return (
    <div className="line-scale">
      <div style={{ backgroundColor: `${theme.color1}` }} />
      <div style={{ backgroundColor: `${theme.color1}` }} />
      <div style={{ backgroundColor: `${theme.color1}` }} />
      <div style={{ backgroundColor: `${theme.color1}` }} />
      <div style={{ backgroundColor: `${theme.color1}` }} />
    </div>
  );
};

export default ScaleLoader;
