import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

import Button from "../common/Button";
import ButtonFixed from "../common/ButtonFixed";
import ItemBlock from "./ItemBlock";
import Title from "../common/Title";

const Step2 = ({
  habitats,
  onFinishStep2,
  habitat_destionation_id,
  habitat_destionation_name,
  habitat_depart_id,
  habitat_depart_name,
  arrival_situation,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const BoxContainerRadioBox = styled.div`
    label {
      &::before {
        border: 2px solid rgb(196, 196, 196);
      }
      &::after {
        background-color: ${theme.color2} !important;
      }
    }
  `;
  const [selectHabitatDepart, setSelectHabitatDepart] = useState({
    id: null,
    name: "",
  });
  const [selectHabitatDestination, setSelectDestination] = useState({
    id: null,
    name: "",
  });

  const [arrivalSituation, setArrivalSituation] = useState("");

  const refBoxDestination = useRef(null);

  const arrivalAccommodationSituation = [
    {
      label: t("tenant"),
      value: "Tenant",
    },
    {
      label: t("owner"),
      value: "Owner",
    },
  ];

  const handleChangeDepart = (e) => {
    if (e.target.checked) {
      setSelectHabitatDepart(JSON.parse(e.target.value));
    }
   // refBoxDestination.current.scrollIntoView();
  };
  const handleChangeDestination = (e) => {
    if (e.target.checked) {
      setSelectDestination(JSON.parse(e.target.value));
    }
  };

  const onSubmitStep2 = () => {
    onFinishStep2({
      habitat_depart_id: selectHabitatDepart.id,
      habitat_depart_name: selectHabitatDepart.name,
      habitat_destionation_id: selectHabitatDestination.id,
      habitat_destionation_name: selectHabitatDestination.name,
      arrivalSituation: arrivalSituation,
    });
  };

  const handleChangeArrivalSituation = (e) => {
    setArrivalSituation(e.target.value);
  };

  useEffect(() => {
    setSelectDestination({
      id: habitat_destionation_id,
      name: habitat_destionation_name,
    });
  }, [habitat_destionation_id, habitat_destionation_name]);

  useEffect(() => {
    setSelectHabitatDepart({
      id: habitat_depart_id,
      name: habitat_depart_name,
    });
  }, [habitat_depart_id, habitat_depart_name]);

  useEffect(() => {
    setArrivalSituation(arrival_situation);
  }, [arrival_situation]);

  return (
    <div className="mb-5 pb-5 mb-lg-0 pb-lg-3">
      <div className="mb-5">
        <ItemBlock
          title={t("yourStartingAccommodation")}
          habitats={habitats}
          habitat_id={selectHabitatDepart.id}
          prefix_id="depart"
          onChange={handleChangeDepart}
          theme={theme}
        />
      </div>

      <div className="mb-3" ref={refBoxDestination}>
        <ItemBlock
          title={t("yourArrivalAccommodation")}
          habitats={habitats}
          habitat_id={selectHabitatDestination.id}
          prefix_id="destination"
          onChange={handleChangeDestination}
          theme={theme}
        />
      </div>
      <div>
        <Title
          title={t("arrivalAccommodationSituation")}
          hiddenIcon
          className="mb-2"
        />
        <div className="d-flex align-items-center">
          {arrivalAccommodationSituation.map((item) => (
            <BoxContainerRadioBox
              className="radio mr-5 d-flex align-items-center pl-1"
              key={item.value}
            >
              <input
                type="radio"
                id={item.value}
                value={item.value}
                name="arrivalSituation"
                defaultChecked={arrivalSituation === item.value}
                onChange={handleChangeArrivalSituation}
              />
              <label htmlFor={item.value} className="pl-2">
                {item.label}
              </label>
            </BoxContainerRadioBox>
          ))}
        </div>
      </div>

      <div className="mt-3">
          <Button
            className="ml-lg-auto"
            disabled={
              !selectHabitatDepart.name ||
              !selectHabitatDestination.name ||
              !arrivalSituation
            }
            onClick={onSubmitStep2}
          >
            {t("following")}
          </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  habitats: state.params.data.habitats,
  habitat_depart_id: state.params.habitat_depart_id,
  habitat_depart_name: state.params.habitat_depart_name,
  habitat_destionation_id: state.params.habitat_destionation_id,
  habitat_destionation_name: state.params.habitat_destionation_name,
  arrival_situation: state.params.arrivalSituation
});

export default connect(mapStateToProps, null)(Step2);
