import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const Button = ({
  isLoading = false,
  disabled = false,
  children,
  ...props
}) => {
  const theme = useTheme();
  const ButtonSt = styled.button`
    color: #fff;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    user-select: none;
    padding: 0.375rem 0.75rem;
    border-radius: 0.3rem !important;
    outline: unset;
    border: 2px solid ${theme.color2};
    background-color: ${theme.color2};
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      border-color: transparent;
      cursor: not-allowed;
    }
  `;
  return (
    <ButtonSt
      className={`demenagement__btn ${props.className && props.className}`}
      disabled={isLoading || disabled}
      {..._.omit(props, ["className"])}
    >
      {isLoading && (
        <Spinner as="span" className="mr-2" size="sm" animation="border" />
      )}
      {children}
    </ButtonSt>
  );
};

export default Button;
