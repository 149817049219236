import React from "react";
import { Row, Col } from "react-bootstrap";

import Title from "../common/Title";
const ItemBlock = ({ title, habitats, habitat_id, prefix_id, onChange, theme}) => {
  return (
    <>
      <Title title={title} className="mb-2" />
      <Row>
        {habitats
          ? habitats.map((habitat) => (
              <Col sm={3} key={habitat.id}>
                <div className="pl-3 pr-3 p-lg-0">
                  <input
                    type="radio"
                    name={`habitat${prefix_id}_id`}
                    checked={habitat.id === habitat_id}
                    hidden
                    id={prefix_id + habitat.id}
                    value={JSON.stringify({
                      id: habitat.id,
                      name: habitat.nom,
                    })}
                    onChange={onChange}
                  />
                  <label
                    htmlFor={prefix_id + habitat.id}
                    className="d-block text-center rounded p-2 demenagement-step2__label-box"
                    style={{
                      backgroundColor: `${habitat.id === habitat_id ? theme.color2 : ""}`,
                    }}
                  >
                    <div className="demenagement-step2__img-box">
                      <div
                        style={{ backgroundImage: `url(${habitat.url})` }}
                        className="demenagement-step2__img-box-inner"
                      />
                    </div>
                    <p className="mt-2 mb-0">{habitat.nom}</p>
                  </label>
                </div>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
};

export default ItemBlock;
