import React from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { Form } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import InputLocation from "../input-location";
import Button from "../common/Button";

import { ReactComponent as IconUser } from "../../assets/images/icon-user.svg";
import { ReactComponent as IconMap } from "../../assets/images/icon-map.svg";
import { ReactComponent as IconHouse } from "../../assets/images/icon-house.svg";
import { ReactComponent as IconPhone } from "../../assets/images/icon-phone.svg";
import { Regex_phone } from "../result-search";
import ButtonFixed from "components/common/ButtonFixed";
import CustomCheckbox from "components/custom-checkbox";

const HomeForm = ({
  visibleStep,
  onSubmit,
  loading,
  visibleInputPhone,
  dataBases,
  visibleResultSearch,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const BoxContainerSvg = styled.span`
    svg {
      path {
        fill: ${theme.color1};
      }
    }
  `;

  const validationSchema = yup.object({
    phone: yup
      .string()
      .matches(Regex_phone, t("invalidPhone"))
      .required(t("phoneIsRequired")),
    email: yup
      .string()
      .required(t("emailIsRequired"))
      .nullable()
      .email(t("invalidEmail")),
    nom: yup.string().required(t("nomIsRequired")),
    depart: yup.object().shape({
      address: yup.string().required(t("departAddressIsRequired")),
    }),
    destination: yup.object().shape({
      address: yup.string().required(t("destinationAddressIsRequired")),
    }),
  });

  const initialValues = {
    phone: dataBases.phone,
    email: dataBases.email,
    nom: dataBases.nom,
    depart: dataBases.depart,
    destination: dataBases.destination,
    checkMark: dataBases.checkMark,
  };

  return (
    <Formik
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <div>
          {(!visibleStep || visibleResultSearch) && (
            <Form noValidate onSubmit={handleSubmit} id="comparo" autoComplete="nope">
              <div className="demenagement__form">
                <Row className="align-items-md-center">
                  <Col sm={12} md={12} lg={10}>
                    <Row>
                      {!visibleStep && (
                        <Col sm={12} md={6}>
                          <div className="mb-3">
                            <div
                              className={`demenagement-input__icon ${
                                touched.nom && !!errors.nom && "is-invalid"
                              }`}
                            >
                              <BoxContainerSvg>
                                <IconUser />
                              </BoxContainerSvg>
                              <input
                                className="demenagement-input"
                                placeholder={t("yourName")}
                                onChange={handleChange}
                                name="nom"
                                value={values.nom}
                                autoComplete="nope"
                              />
                            </div>
                            <p
                              className={`demenagement-input__error-msg ${
                                touched.nom && !!errors.nom && "is-invalid"
                              }`}
                            >
                              {t("requiredFields")}
                            </p>
                          </div>
                        </Col>
                      )}
                      {!visibleStep && (
                        <Col sm={12} md={6}>
                          <div className="row">
                            <div className="mb-3 col-12 col-md-6">
                              <div
                                className={`demenagement-input__icon ${
                                  touched.email &&
                                  !!errors.email &&
                                  "is-invalid"
                                }`}
                              >
                                <BoxContainerSvg>
                                  <IconMap />
                                </BoxContainerSvg>
                                <input
                                  className="demenagement-input"
                                  placeholder={t("yourMail")}
                                  onChange={handleChange}
                                  name="email"
                                  value={values.email}
                                  autoComplete="nope"
                                />
                              </div>
                              <p
                                className={`demenagement-input__error-msg  apart ${
                                  touched.email &&
                                  !!errors.email &&
                                  "is-invalid"
                                }`}
                              >
                                {errors.email}
                              </p>
                            </div>

                            <div className="mb-3 col-12 col-md-6">
                              <div
                                className={`demenagement-input__icon ${
                                  touched.phone &&
                                  !!errors.phone &&
                                  "is-invalid"
                                }`}
                              >
                                <BoxContainerSvg>
                                  <IconPhone />
                                </BoxContainerSvg>
                                <input
                                  className="demenagement-input"
                                  placeholder={t("yourPhone")}
                                  onChange={handleChange}
                                  name="phone"
                                  value={values.phone}
                                  autoComplete="nope"
                                />
                              </div>
                              <p
                                className={`demenagement-input__error-msg apart ${
                                  touched.phone &&
                                  !!errors.phone &&
                                  "is-invalid"
                                }`}
                              >
                                {errors.phone}
                              </p>
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col sm={12} md={6}>
                        {!visibleStep ||
                        (visibleResultSearch && window.innerWidth >= 992) ? (
                          <Field name="depart">
                            {({ field, form, meta }) => (
                              <InputLocation
                                field={field}
                                form={form}
                                meta={meta}
                                placeholder={t("departAddress")}
                              >
                                <BoxContainerSvg>
                                  <IconHouse />
                                </BoxContainerSvg>
                              </InputLocation>
                            )}
                          </Field>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col sm={12} md={6}>
                        {!visibleStep ||
                        (visibleResultSearch && window.innerWidth >= 992) ? (
                          <Field name="destination">
                            {({ field, form, meta }) => (
                              <InputLocation
                                field={field}
                                form={form}
                                meta={meta}
                                placeholder={t("destinationAddress")}
                              >
                                <BoxContainerSvg>
                                  <IconHouse />
                                </BoxContainerSvg>
                              </InputLocation>
                            )}
                          </Field>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    {!visibleStep && (
                      <div className="d-md-none">
                        <CustomCheckbox
                          content={t("confirmInformation")}
                          onChange={handleChange}
                          name="checkMark"
                          value={values.checkMark}
                        />
                      </div>
                    )}
                  </Col>
                  <Col sm={12} md={12} lg={2}>

                      <Button
                        className="w-100 mb-lg-3 mt-2"
                        type="submit"
                        isLoading={loading}
                      >
                        {visibleResultSearch ? t("modify") : t("search")}
                      </Button>
          
                  </Col>
                </Row>
              {!visibleStep && (
                <div className="d-none d-md-block pl-3">
                  <CustomCheckbox
                    content={t("confirmInformation")}
                    onChange={handleChange}
                    name="checkMark"
                    value={values.checkMark}
                  />
                </div>
              )}
              </div>

            </Form>
          )}
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  dataBases: state.bases,
});
export default connect(mapStateToProps, null)(HomeForm);
