import { createReducer } from "@reduxjs/toolkit";
import {
  SET_TRACK_ACTION,
  SET_TRACK_LEAD,
  SET_TRACK_LEAD_ID,
  SET_TRACK_QUOTE,
  SET_TRACK_SELECTION,
} from "logic/actions/track";

const initialState = {
  lead_id: "",
  actions: [],
  selections: [],
  lead: [],
  quote: [],
};

const handleSetTrackAction = (state, { payload: { action } }) => {
  state.actions = action;
};

const handleSetTrackLead = (state, { payload: { lead } }) => {
  state.lead.push(lead);
};

const handleSetTrackSelection = (state, { payload: { selection } }) => {
  state.selections = selection;
};

const handleSetTrackQuote = (state, { payload: { quote } }) => {
  state.quote = quote;
};

const handleSetTrackLeadId = (state, { payload: { lead_id } }) => {
  state.lead_id = lead_id;
};
export default createReducer(initialState, {
  [SET_TRACK_ACTION]: handleSetTrackAction,
  [SET_TRACK_LEAD]: handleSetTrackLead,
  [SET_TRACK_SELECTION]: handleSetTrackSelection,
  [SET_TRACK_QUOTE]: handleSetTrackQuote,
  [SET_TRACK_LEAD_ID]: handleSetTrackLeadId,
});
