import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import deepfilter from "deep-filter";

import { volatileReducers } from "./reducers";
import env from "../helpers/env";

const volatileReducer = combineReducers(volatileReducers);

const node_root = document.getElementById("demenagement");

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify({
      ...state,
      params: { ...state.params, visibleResultSearch: false, providers: {} },
    });
    sessionStorage.setItem("demenagement", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = sessionStorage.getItem("demenagement");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const persistedState = loadFromLocalStorage();

const client = axios.create({
  baseURL: "https://hub.mobixgroup.com/client",
  params: { key: node_root.dataset.key },
  responseType: "json",
  headers: { "Content-Type": "application/json" },
});

const axiosMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      ({ getState, getSourceAction }, request) => {
        const {
          persistent: {
            authReducer: { accessToken, loggedIn = false } = {},
          } = {},
        } = getState();

        const { noAuth = false } = getSourceAction(request);

        if (loggedIn && !accessToken && !env.isProd) {
          console.error("Logged in but no accessToken !"); // eslint-disable-line no-console
        }

        if (accessToken && !noAuth) {
          request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return request;
      },
    ],
    response: [
      {
        success(c, res) {
          // Filter out null values to allow for simplier destructuring (null values become undefined, so default values are used instead)
          res.data = deepfilter(res.data, (v) => v !== null);

          return Promise.resolve(res);
        },
        error({ getState, dispatch }, error = {}) {
          const {
            response: {
              status: responseStatus = 0,
              data: responseData = {},
            } = {},
            config: { method: requestMethod = "", url: requestUrl = "" } = {},
          } = error;

          const {
            persistent: { authReducer: { loggedIn = false } = {} } = {},
          } = getState();

          let errorMessage = `${requestMethod.toUpperCase()} ${requestUrl}: `;
          if (responseStatus) {
            if (responseData) {
              errorMessage += `${responseStatus} error from backend with data: ${JSON.stringify(
                responseData
              )}`;
            } else {
              errorMessage += `${responseStatus} error`;
            }
          } else {
            errorMessage += "No response from backend";
          }

          if (!env.isProd) {
            console.error(errorMessage); // eslint-disable-line no-console
          }

          switch (responseStatus) {
            case 429:
              alert("Too many request"); // eslint-disable-line no-alert
              break;

            case 401:
              if (loggedIn) {
              }
              break;

            case 0:
              break;

            default:
              Sentry.captureException(errorMessage);
          }

          return Promise.reject(error);
        },
      },
    ],
  },
};

const store = createStore(
  volatileReducer,
  persistedState,
  composeWithDevTools(
    applyMiddleware(thunk, axiosMiddleware(client, axiosMiddlewareOptions))
  )
);

store.subscribe(() => {
  const state = store.getState();
  saveToLocalStorage(state);
});

export { store };
