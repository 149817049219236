import React from "react";
import * as yup from "yup";
import { Form } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { parse } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as IconM2 } from "../../assets/images/icon-m2.svg";
import { ReactComponent as WhiteIconM2 } from "../../assets/images/white-icon-m2.svg";

import { ReactComponent as IconDate } from "../../assets/images/icon-date.svg";
import { ReactComponent as WhiteIconDate } from "../../assets/images/white-icon-date.svg";

import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import Button from "../common/Button";
import Title from "../common/Title";
import ButtonFixed from "../common/ButtonFixed";
import { th } from "date-fns/locale";

registerLocale("fr", fr);
registerLocale("en", en);
const node_root = document.getElementById("demenagement");

const ReactDatePickerInput = React.forwardRef((props, ref) => (
  <input ref={ref} {...props} />
));
ReactDatePickerInput.displayName = "ReactDatePickerInput";

const Step1 = ({ onSubmit, surface, date_depart }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const BoxWrapDatePicker = styled.div`
    .react-datepicker__navigation--next {
      border-left-color: ${theme.color1};
    }
    .react-datepicker__navigation--previous {
      border-right-color: ${theme.color1};
    }
  `;
  return (
    <Formik
      validateOnChange={false}
      validationSchema={yup.object({
        surface: yup
          .number()
          .required(t("surfaceIsRequired"))
          .typeError(t("surfaceIsRequired")),
        date_depart: yup.date().nullable().required(t("dateIsRequired")),
      })}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        surface: surface,
        date_depart: date_depart
          ? parse(date_depart, "dd/MM/yyyy", new Date())
          : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form className="form-login" noValidate onSubmit={handleSubmit}>
          <BoxWrapDatePicker>
            <Title
              className="mb-2 "
              title={t("movingDate")}
              hiddenIcon={true}
            />
            <div
              className={`demenagement-step1__input ${
                touched.date_depart && !!errors.date_depart && "is-invalid"
              }`}
              style={{
                borderColor: `${values.date_depart ? theme.color2 : ""}`,
              }}
            >
              <DatePicker
                showPopperArrow={false}
                locale={node_root.dataset.lang}
                minDate={new Date()}
                name="date_depart"
                dateFormat="dd/MM/yyyy"
                placeholderText={t("placeholderDate")}
                selected={values.date_depart}
                onChange={(date) => {
                  setFieldValue("date_depart", date);
                }}
                customInput={<ReactDatePickerInput className="form-control" />}
              />
              <span
                style={{
                  backgroundColor: `${values.date_depart ? theme.color2 : ""}`,
                }}
              >
                {values.date_depart ? <WhiteIconDate /> : <IconDate />}
              </span>
            </div>

            <Form.Control.Feedback
              type="invalid"
              className={`${
                touched.date_depart && !!errors.date_depart && "show"
              }`}
            >
              {errors.date_depart}
            </Form.Control.Feedback>
          </BoxWrapDatePicker>
          <div className="mt-3">
            <Title className="mb-2" title={t("surfaceToBeMoved")}/>
            <div
              className={`demenagement-step1__input ${
                touched.surface && !!errors.surface && "is-invalid"
              }`}
              style={{ borderColor: `${values.surface ? theme.color2 : ""}` }}
            >
              <input
                placeholder="ex: 80"
                onChange={handleChange}
                name="surface"
                type="number"
                value={values.surface}
              />
              <span
                style={{
                  backgroundColor: `${values.surface ? theme.color2 : ""}`,
                }}
              >
                {values.surface ? <WhiteIconM2 /> : <IconM2 />}
              </span>
            </div>

            <Form.Control.Feedback
              type="invalid"
              className={`${touched.surface && !!errors.surface && "show"}`}
            >
              {errors.surface}
            </Form.Control.Feedback>
          </div>
          <div className="text-md-right mt-3">
              <Button className="ml-lg-auto" type="submit">
                {t("following")}
              </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  surface: state.params.surface,
  date_depart: state.params.date_depart,
});

export default connect(mapStateToProps, null)(Step1);
