import React, { useState, useEffect } from "react";
import { FormControl, InputGroup, Form, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import _ from "lodash";

import ScaleLoader from "../common/ScaleLoader";
import { setVisibleResultResearch } from "../../logic/actions/param";
import { ReactComponent as IconBell } from "../../assets/images/icon-bell.svg";
import { ReactComponent as IconSelected } from "../../assets/images/icon-selected-card.svg";
import { ReactComponent as IconClose } from "../../assets/images/icon-close.svg";
import { ID_SOLUTION_SPECIAL } from "../../constants";
import {
  sendTrackData,
  setTrackAction,
  setTrackLead,
  setTrackQuote,
  setTrackSelection,
  setTrackLeadId,
} from "logic/actions/track";
import { store } from "logic";
import { ReactComponent as NoResultIcon } from "../../assets/images/no-result-icon.svg";
import { setAddressEmail } from "logic/actions/base";

const Regex_email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const Regex_phone = /^[0-9]\d{9}$/g;

const RenderStar = ({ quantity }) => {
  const arr = new Array(quantity).fill("star");
  return arr.map((item, index) => (
    <i className="fa fa-star" key={item + index} aria-hidden="true"></i>
  ));
};

const ResultSearch = ({
  params,
  providers,
  solutions,
  onFinishResultSearch,
  onChangeCurrentSolution,
  loading,
  visibleInputPhone,
  visibleModalSuccess,
  setVisibleModalSuccess,
  setVisibleResultResearch,
  setActiveKey,
  yourMail,
  loadingBtnSend,
  setTrackLead,
  setTrackAction,
  setTrackSelection,
  setTrackQuote,
  sendTrackData,
  setTrackLeadId,
  setAddressEmail
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { projets: projects } = providers;
  const {
    services,
    surface,
    date_depart,
    habitat_depart_name,
    habitat_destionation_name,
  } = params;
  const [selectProject, setSelectProject] = useState([]);
  const [selectSolution, setSelectSolution] = useState(1);
  const [selectIdTypeSort, setSelectIdTypeSort] = useState("price");
  const [addressMail, setAddressMail] = useState(yourMail);
  const [messageErrorInput, setMessageErrorInput] = useState("");
  const [allProject, setAllProject] = useState([]);

  const TYPE_SORT = [
    { id: "price", title: t("price") },
    // { id: 2, title: t("quantity") },
    { id: "review", title: t("notes") },
  ];

  const handleChangeProject = (e) => {
    if (e.target.checked) {
      // set tracking
      setTrackAction([
        {
          name: t("selectedQuote"),
          step: "select",
        },
      ]);

      setTrackSelection([JSON.parse(e.target.value)]);
      sendTrackData(store.getState().track);
      setSelectProject([...selectProject, JSON.parse(e.target.value)]);
    } else {
      setSelectProject(
        selectProject.filter((item) => JSON.stringify(item) !== e.target.value)
      );
    }
  };

  const handleChangeSolution = (idSolution) => {
    setSelectSolution(idSolution);
    onChangeCurrentSolution(idSolution);
  };

  const handleChangeInput = (e) => {
    setAddressMail(e.target.value);
    if (Regex_email.test(e.target.value)) {
      setMessageErrorInput("");
      setAddressEmail(e.target.value);
    } else {
      setMessageErrorInput(t("invalidEmail"));
    }
  };

  useEffect(() => {
    if (selectIdTypeSort === "price") {
      setAllProject(
        _.orderBy(projects, (item) => parseFloat(item.price), "asc")
      );
    } else {
      setAllProject(
        _.orderBy(projects, (item) => parseFloat(item.review), "desc")
      );
    }
  }, [projects, selectIdTypeSort]);

  const isSelected = (project) => {
    return selectProject.some((item) =>
      _.isEqual(
        {
          prestataire_id: item.id,
          solution_id: item.solution_id,
          price: Number(item.price),
          current_solution: item.current_solution,
        },
        {
          prestataire_id: project.id,
          solution_id: project.solution_id,
          price: Number(project.price),
          current_solution: selectSolution,
        }
      )
    );
  };
    
  return (
    <>
      <Modal
        show={visibleModalSuccess}
        onHide={() => {
          setVisibleModalSuccess(false);
          sessionStorage.removeItem("demenagement");
        }}
        dialogClassName="demenagement__modal"
        style={{ "--modal-color": `${theme.color1}` }}
        className="demenagement-wrap"
        bsPrefix="demenagement-modal"
      >
        <div>
          <div className="demenagement__modal-header">
            <p
              onClick={() => {
                setVisibleModalSuccess(false);
                sessionStorage.removeItem("demenagement");
              }}
            >
              <span>Close</span>
              <span>
                <IconClose />
              </span>
            </p>
          </div>
          <p className="demenagement__modal-content">
            {t("titleModalSuccess")}
          </p>
        </div>
      </Modal>

      <div className="mt-5 demenagement-result">
        <p style={{ color: theme.color1 }}>{t("summaryOfMyRequest")}</p>
        <p className="mb-0 demenagement-result__info-bar demenagement__font-other">
          <span
            style={{ backgroundColor: `${theme.color1}` }}
            onClick={() => {
              setVisibleResultResearch(false);
              setActiveKey("step1");
            }}
          >
            {surface} m2
          </span>
          <span
            style={{ backgroundColor: `${theme.color1}` }}
            onClick={() => {
              setVisibleResultResearch(false);
              setActiveKey("step1");
            }}
          >
            {date_depart}
          </span>
          <span
            style={{ backgroundColor: `${theme.color1}` }}
            onClick={() => {
              setVisibleResultResearch(false);
              setActiveKey("step2");
            }}
          >
            {habitat_depart_name}
          </span>
          <span
            style={{ backgroundColor: `${theme.color1}` }}
            onClick={() => {
              setVisibleResultResearch(false);
              setActiveKey("step2");
            }}
          >
            {habitat_destionation_name}
          </span>
          <span
            style={{ backgroundColor: `${theme.color1}` }}
            onClick={() => {
              setVisibleResultResearch(false);
              setActiveKey("step3");
            }}
          >
            {services.length} {t("services2")}
          </span>
        </p>

        <div
          className="mt-lg-3 p-3 demenagement-result__wrap-box-mail"
          style={{ backgroundColor: `${theme.color1}` }}
        >
          <div className="d-lg-flex align-items-lg-center demenagement-result__box-mail">
            <p className="mb-3 mb-lg-0 d-flex align-items-start">
              <span
                className={`${selectProject.length ? "animation-bell" : ""}`}
              >
                <IconBell />
              </span>
              <span className="pl-3">
                {t("youWantToReceiveTheBestOffersByEmail")}
              </span>
            </p>
            <div className="d-flex">
              <InputGroup size="lg">
                <FormControl
                  size="lg"
                  placeholder={t("enterAnEmailAddress")}
                  className="mr-2 rounded"
                  value={addressMail}
                  onChange={handleChangeInput}
                  isInvalid={messageErrorInput.length}
                />
                <Form.Control.Feedback type="invalid">
                  {messageErrorInput}
                </Form.Control.Feedback>
              </InputGroup>
              <button
                className="demenagement__button"
                onClick={() => onFinishResultSearch(selectProject)}
                disabled={!selectProject.length || loadingBtnSend}
                style={{
                  borderColor: `${
                    selectProject.length ? theme.color2 : "#e8e8e8"
                  }`,
                  opacity: 1,
                  backgroundColor: `${
                    selectProject.length ? theme.color2 : "#e8e8e8"
                  }`,
                }}
              >
                {loadingBtnSend && (
                  <Spinner
                    as="span"
                    className="mr-2"
                    size="sm"
                    animation="border"
                  />
                )}
                {t("toSend")}
              </button>
            </div>
          </div>
          {selectProject.length ? (
            <p className="mb-0 mt-1 text-right w-100 text-white">
              ( {selectProject.length} {t("selectedOffers")} )
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="mt-3 d-lg-flex justify-content-lg-between align-item-lg-center">
          <p className="mb-3 mb-lg-0" style={{ color: theme.color1 }}>
            {/* {projects && projects.length} */}
            {t("quantityResultOffers", {
              quantity: projects && projects.length,
            })}
          </p>
          <p className="mb-0">
            <span>{t("sortBar")}</span>
            {TYPE_SORT.map((item, index) => (
              <span
                onClick={() => setSelectIdTypeSort(item.id)}
                key={item.id}
                className="rounded p-2 ml-2"
                style={{
                  cursor: "pointer",
                  border: `1px solid ${
                    selectIdTypeSort === item.id ? theme.color1 : "#787878"
                  }`,
                  color: `${
                    selectIdTypeSort === item.id ? theme.color1 : "#787878"
                  }`,
                }}
              >
                {item.title}
              </span>
            ))}
          </p>
        </div>

        <div>
          <div className="mt-3 demenagement-result-solution-bar">
            <div
              onClick={() => {
                if (selectSolution) {
                  handleChangeSolution(0);
                  // setSelectProject([]);
                }
              }}
              className={`demenagement-result-solution-bar__item ${
                !selectSolution && "active"
              }`}
              style={{
                backgroundColor: `${!selectSolution ? theme.color2 : "#fff"}`,
                borderColor: `${!selectSolution ? theme.color2 : "#ddd"}`,
              }}
            >
              <p className="mb-0">{t("ourSelection")}</p>
              <p className="mb-0">{t("LeTopOffres")}</p>
            </div>
            {solutions
              ? solutions.map((solution) => (
                  <div
                    onClick={() => {
                      if (selectSolution !== solution.id) {
                        handleChangeSolution(solution.id);
                        // setSelectProject([]);
                      }
                    }}
                    key={solution.id}
                    className={`demenagement-result-solution-bar__item ${
                      solution.id === selectSolution && "active"
                    }`}
                    style={{
                      backgroundColor: `${
                        solution.id === selectSolution ? theme.color2 : "#fff"
                      }`,
                      borderColor: `${
                        solution.id === selectSolution ? theme.color2 : "#ddd"
                      }`,
                    }}
                  >
                    <p className="mb-0">
                      {providers[`solution_${solution.id}`]}
                      &nbsp;
                      {t("offers")}
                    </p>
                    <p className="mb-0">{solution.nom}</p>
                  </div>
                ))
              : ""}
          </div>
        </div>

        {loading ? (
          <div className="text-center mt-3">
            <ScaleLoader />
          </div>
        ) : (
          <div className="mt-5">
            {allProject.length > 0 ? (
              allProject.map((project, index) => (
                <div
                  key={index}
                  className={`p-2 border rounded mb-3 d-lg-flex demenagement-result-card ${
                    isSelected(project) ? "active" : "abc"
                  }`}
                  style={{ "--active-bgr": `${theme.color1}` }}
                >
                  <div className="d-flex demenagement-result-card__left">
                    <div>
                      <div>
                        <img src={project.logo} alt="logo" />
                      </div>
                      <p className="mb-0 mt-2 demenagement-result-card__price">
                        <span>{parseFloat(project.price).toFixed(2)}€</span>
                        <span>&nbsp;TTC</span>
                      </p>
                      <p className="mb-0">
                        <small>{t("from")}</small>
                      </p>
                    </div>
                    {window.innerWidth < 992 && (
                      <div>
                        <h4 className="mb-3 demenagement-result-card__title">
                          {project.nom}
                        </h4>
                        <div className="mb-2 d-flex">
                          {!!project.review && (
                            <p className="mb-0">
                              <RenderStar quantity={project.review} />
                            </p>
                          )}
                          {/* <p className="mb-0 pl-2">34 {t("notice")}</p> */}
                        </div>
                        <p className="mb-0 demenagement__font-other">
                          {project.description}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="demenagement-result-card__center">
                    {window.innerWidth >= 992 && (
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h4 className="mb-0 demenagement-result-card__title">
                            {project.nom}
                          </h4>
                          <div className="pl-3 d-flex">
                            {!!project.review && (
                              <p className="mb-0">
                                <RenderStar quantity={project.review} />
                              </p>
                            )}
                            {/* <p className="mb-0 pl-2 d-flex align-items-center">
                              34 {t("notice")}
                            </p> */}
                          </div>
                        </div>
                        <p className="demenagement__font-other">
                          {project.description}
                        </p>
                      </div>
                    )}
                    <div className="mt-3 d-flex flex-wrap demenagement-result-card__options">
                      {Object.values(project.options).map((option, index) => (
                        <p
                          key={option.nom + index}
                          className="mb-1 mb-lg-0 mr-2 mr-lg-3"
                        >
                          <i
                            className={`fa ${option.class}`}
                            style={{ color: theme.color1 }}
                            aria-hidden="true"
                          ></i>
                          <span className="pl-1 demenagement__font-other">
                            {option.nom}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>

                  <div className="mt-3 mt-lg-0 demenagement-result-card__right text-center">
                    <label>
                      <input
                        defaultChecked={isSelected(project)}
                        hidden
                        name="projects"
                        type="checkbox"
                        value={JSON.stringify({
                          prestataire_id: project.id,
                          solution_id: project.solution_id,
                          price: Number(project.price),
                          current_solution: selectSolution,
                          ...project,
                        })}
                        onChange={handleChangeProject}
                      />
                      <div>
                        <span
                          style={{
                            backgroundColor: `${theme.color1}`,
                            borderColor: `${theme.color1}`,
                          }}
                          className="demenagement__button"
                          onClick={() => {
                            if (
                              project.solution_id.toString() ===
                              ID_SOLUTION_SPECIAL
                            ) {
                              window.open(project.link, "_blank").focus();
                            }
                          }}
                        >
                          {project.solution_id.toString() ===
                          ID_SOLUTION_SPECIAL
                            ? t("showOffer")
                            : t("receiveTheQuote")}
                        </span>
                        <span
                          className="demenagement__button"
                          style={{
                            backgroundColor: `${theme.color2}`,
                            borderColor: `${theme.color2}`,
                          }}
                        >
                          <IconSelected />
                          <span className="pl-1">{t("selectedQuote")}</span>
                        </span>
                      </div>
                    </label>
                  
                    {isSelected(project) && (
                      <p className="demenagement__check-des">
                        {t("advisorContact")}
                      </p>
                    )}
                 </div>
                </div>
              ))
            ) : (
              <div className="p-2 border rounded mb-3 d-lg-flex demenagement-result-card card-no-result">
                <div className="d-flex demenagement-result-card__left">
                  <div>
                    <NoResultIcon />
                  </div>
                </div>
                <div className="demenagement-result-card__center no-result">
                  <div>
                    <div className="d-flex align-items-center mb-3 mt-3">
                      <h4 className="mb-0 demenagement-result-card__title">
                        {t("noresultTitle")}
                      </h4>
                    </div>
                    <p className="demenagement__font-other">
                      {t("noresultDescriition")}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setVisibleResultResearch: bindActionCreators(
    setVisibleResultResearch,
    dispatch
  ),
  setTrackAction: bindActionCreators(setTrackAction, dispatch),
  setTrackLead: bindActionCreators(setTrackLead, dispatch),
  setTrackSelection: bindActionCreators(setTrackSelection, dispatch),
  setTrackQuote: bindActionCreators(setTrackQuote, dispatch),
  sendTrackData: bindActionCreators(sendTrackData, dispatch),
  setTrackLeadId: bindActionCreators(setTrackLeadId, dispatch),
  setAddressEmail: bindActionCreators(setAddressEmail, dispatch)
});

const mapStateToProps = (state) => ({
  params: state.params,
  solutions: state.params.data.solutions,
  providers: state.params.providers,
  yourMail: state.bases.email,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSearch);
