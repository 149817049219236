import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
const lang = document.getElementById("demenagement").dataset.lang;

const InputLocation = (props) => {
  const { t } = useTranslation();
  const { field, meta, placeholder, children } = props;
  const [libraries] = useState(["places"]);
  const [refSearch, setRefSearch] = useState(null);
  const [value, setValue] = useState("");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBP9eZutmXTr96nrmr51Zsb8qbHBAgByQ8",
    libraries,
    region: lang.toUpperCase(),
    language: lang,
    preventGoogleFontsLoading: true,
  });
  const onLoad = (ref) => {
    setRefSearch(ref);
  };

  const handlePlacesChanged = () => {
    if (refSearch !== null) {
      const place = refSearch.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      props.form.setFieldValue(props.field.name, {
        address: place.formatted_address,
        address_components: place.address_components,
        location: {
          lat,
          lng,
        },
      });
      setValue("");
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    props.form.setFieldValue(props.field.name, {
      address: "",
    });
  };

  return (
    isLoaded && (
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={handlePlacesChanged}
        restrictions={{ country: lang }}
      >
        <div className="mb-3">
          <div
            className={`demenagement-input__icon ${
              meta.error && meta.error.address && "is-invalid"
            }`}
          >
            {children}
            <input
              className="demenagement-input"
              placeholder={placeholder}
              name={field.name}
              type="search"
              defaultValue={field.value.address}
              autoComplete="off"
              onChange={handleChange}
            />
          </div>
          <p
            className={`demenagement-input__error-msg ${
              meta.error && meta.error.address && "is-invalid"
            }`}
          >
            {value ? t("invalidAddress") : t("requiredFields")}
          </p>
        </div>
      </Autocomplete>
    )
  );
};

export default InputLocation;
