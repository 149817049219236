export const SET_TRACK_ACTION = "SET_TRACK_ACTION";
export const SEND_TRACK_DATA = "SEND_TRACK_DATA";
export const SET_TRACK_LEAD = "SET_TRACK_LEAD";
export const SET_TRACK_SELECTION = "SET_TRACK_SELECTION";
export const SET_TRACK_QUOTE = "SET_TRACK_QUOTE";
export const SET_TRACK_LEAD_ID = "SET_TRACK_LEAD_ID";

export const setTrackAction = (action) => ({
  type: SET_TRACK_ACTION,
  payload: {
    action,
  },
});

export const setTrackLead = (lead) => ({
  type: SET_TRACK_LEAD,
  payload: {
    lead,
  },
});

export const sendTrackData = (data) => {
  return {
    type: SEND_TRACK_DATA,
    payload: {
      request: {
        method: "POST",
        url: `/tracking`,
        data,
      },
    },
  };
};

export const setTrackSelection = (selection) => {
  return {
    type: SET_TRACK_SELECTION,
    payload: {
      selection,
    },
  };
};

export const setTrackQuote = (quote) => {
  return {
    type: SET_TRACK_QUOTE,
    payload: {
      quote,
    },
  };
};

export const setTrackLeadId = (lead_id) => {
  return {
    type: SET_TRACK_LEAD_ID,
    payload: {
      lead_id,
    },
  };
};
