import { createReducer } from "@reduxjs/toolkit";
import { success } from "./helpers";
import {
  SET_LEAD_ID,
  GET_PARAMS,
  SET_PROVIDERS,
  SET_INFO_STEP_1,
  SET_INFO_STEP_2,
  SET_SERVICES,
  SET_VISIBLE_INPUT_PHONE,
  SET_VISIBLE_MODAL_SUCCESS,
  SET_VISIBLE_RESULT_RESEARCH,
  RELOAD_PARAMS,
  SET_PROVIDERS_SOLUTION_SPECIAL,
  SET_PROJETS_SOLUTION_SPECIAL,
  SET_VISIBLE_STEP
} from "../actions/param";
import step1 from "components/step1";

const initialState = {
  data: {},
  lead_id: null,
  date_depart: "",
  surface: null,
  habitat_depart_id: null,
  habitat_depart_name: "",
  habitat_destionation_id: null,
  habitat_destionation_name: "",
  services: [],
  providers: {},
  current_solution: 1,
  visibleStep: false,
  visibleResultSearch: false,
  visibleInputPhone: false,
  visibleModalSuccess: false,
  projetsSolutionSpecial: [],
  arrivalSituation: ""
};

const handleGetParams = (state, { payload: { data: params } }) => {
  if (params.status) {
    state.data = params.data;
  }
};

const handleSetLead = (state, { payload: { lead_id } }) => {
  state.lead_id = lead_id;
  state.visibleStep = true;
};

const handleSetInfoStep1 = (state, { payload }) => {
  state.date_depart = payload.date_depart;
  state.surface = payload.surface;
  state.visibleStep = true;
};

const handleSetInfoStep2 = (state, { payload }) => {
  state.habitat_depart_id = payload.habitat_depart_id;
  state.habitat_depart_name = payload.habitat_depart_name;
  state.habitat_destionation_id = payload.habitat_destionation_id;
  state.habitat_destionation_name = payload.habitat_destionation_name;
  state.arrivalSituation = payload.arrivalSituation;
  state.visibleStep = true;
};

const handleSetProviders = (state, { payload: { providers } }) => {
  state.providers = providers;
  state.visibleResultSearch = true;
};

const handleSetServices = (state, { payload: { services } }) => {
  state.services = services;
};

const handleSetVisibleInputPhone = (state, { payload }) => {
  state.visibleInputPhone = payload.visible;
};

const handleSetVisibleModalSuccess = (state, { payload }) => {
  state.visibleModalSuccess = payload.visible;
};

const handleSetVisibleResultResearch = (state, { payload }) => {
  state.visibleResultSearch = payload.visible;
};

const handleReloadParams = (state) => {
  state.date_depart = "";
  state.surface = "";
  state.habitat_depart_id = null;
  state.habitat_depart_name = "";
  state.habitat_destionation_id = null;
  state.habitat_destionation_name = "";
  state.services = [];
  state.visibleResultSearch = false;
};

const handleSetProvidersSolutionSpecial = (state, { payload }) => {
  state.providers = { ...state.providers, projets: payload };
  state.visibleResultSearch = true;
};

const handleSetProjetsSolutionSpecial = (state, { payload }) => {
  state.projetsSolutionSpecial = payload;
};

const handleVisibleStep = (state, { payload }) => {
  state.visibleStep = payload.visible;
};

export default createReducer(initialState, {
  [success(GET_PARAMS)]: handleGetParams,
  [SET_LEAD_ID]: handleSetLead,
  [SET_INFO_STEP_1]: handleSetInfoStep1,
  [SET_INFO_STEP_2]: handleSetInfoStep2,
  [SET_PROVIDERS]: handleSetProviders,
  [SET_SERVICES]: handleSetServices,
  [SET_VISIBLE_INPUT_PHONE]: handleSetVisibleInputPhone,
  [SET_VISIBLE_MODAL_SUCCESS]: handleSetVisibleModalSuccess,
  [SET_VISIBLE_RESULT_RESEARCH]: handleSetVisibleResultResearch,
  [RELOAD_PARAMS]: handleReloadParams,
  [SET_PROVIDERS_SOLUTION_SPECIAL]: handleSetProvidersSolutionSpecial,
  [SET_PROJETS_SOLUTION_SPECIAL]: handleSetProjetsSolutionSpecial,
  [SET_VISIBLE_STEP]: handleVisibleStep
});
