const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} = process.env;

const env = {
  isProd: NODE_ENV === 'production',
  apiUrl: REACT_APP_API_URL,
  clientId: REACT_APP_CLIENT_ID,
  clientSecret: REACT_APP_CLIENT_SECRET,
  //sentryDSN: 'https://5fe24d1d2ef94ab5bca3304042ca7521@o204930.ingest.sentry.io/5377172',
};

export default env;
